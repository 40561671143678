import React from 'react'
import { connect } from 'react-redux'

import { submitEmail } from '../actions'

import './form.styl'

class Form extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: '',
    }
  }

  onClick = event => {
    event.preventDefault()
    this.props.submitEmail(this.state.value)
  }

  onChange = event => {
    event.preventDefault()
    this.setState({
      value: event.target.value,
    })
  }

  render() {
    return (
      <div>
        <div className="email-form">
          <form onSubmit={this.onClick}>
            <input
              className="email-entry"
              type="email"
              placeholder="enter email"
              value={this.state.value}
              onChange={this.onChange}
            ></input>
            <input
              className="submit-button"
              type="button"
              value="Try It"
              onClick={this.onClick}
            ></input>
          </form>
          {this.props.status === 'failure' ? (
            <div className="error-text">
              Something went wrong! Please enter a valid email and try again.
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  status: state.status,
})

const mapDispatchToProps = dispatch => ({
  submitEmail: email => dispatch(submitEmail(email)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Form)

import React from 'react'
import ReactGA from 'react-ga'

import './welcome.styl'

class Welcome extends React.Component {
  constructor(props) {
    super(props)
  }

  // onClick = () => {
  //   this.props.setRand()
  //   const anotherRandomVal = Math.floor(Math.random() * 10)
  //   this.props.updateMetadata(anotherRandomVal)
  // }

  componentDidMount() {
    ReactGA.pageview('/welcome')
  }

  render() {
    return (
      <div className="welcome-page">
        <div id="welcome-header">
          <a href="/">
            <div id="logo-1"></div>
          </a>
        </div>
        <div className="content-container" id="welcome-content">
          <h2 className="welcome-header">Thanks for joining Keto Digest 🥳</h2>
          <em id="subheading">
            Welcome to the most informative and entertaining keto newsletter on the web!
          </em>
          <p>
            <strong>What's next?</strong>
            <br />
            Every week, you'll get a fresh serving of keto content straight to your inbox. Some
            examples of what you might see:
            <ul>
              <li>Weekly challenges, tips, and tricks</li>
              <li>Deep dives into complicated keto topics</li>
              <li>Curated low-carb recipes and cheffing advice</li>
              <li>Interviews with successfull and experienced keto eaters</li>
            </ul>
          </p>
          <p>
            <strong>Keep Calm and Keto On,</strong>
          </p>
          <p>
            <strong>— The Keto Digest Team 🥑</strong>
          </p>
          <div id="welcome-footer">
            <div className="content-container">
              <em>
                P.S. If you don't see our welcome email verification email, please check your spam
                folder (and promotions tab) and move us to your inbox!
              </em>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

// const mapStateToProps = state=> ({
//   rand: state.rand,
// })
//
// const mapDispatchToProps = dispatch => ({
//   setRand: () => dispatch(setRand()),
//   updateMetadata: val => dispatch(updateMetadata(val)),
// })

// export default connect(mapStateToProps, mapDispatchToProps)(Home)
export default Welcome

import ReactGA from 'react-ga'
import { subscribe } from '../api'

export const ActionTypes = {
  START_EMAIL_SUBMIT: 'START_EMAIL_SUBMIT',
  EMAIL_SUBMIT_SUCCESS: 'EMAIL_SUBMIT_SUCCESS',
  EMAIL_SUBMIT_FAILURE: 'EMAIL_SUBMIT_FAILURE',
}

// Thunk to write metadata
export function submitEmail(email) {
  return async dispatch => {
    ReactGA.event({
      category: 'Sign Up',
      action: 'User clicked to submit',
    })
    dispatch({
      type: ActionTypes.START_EMAIL_SUBMIT,
    })

    try {
      let result = await subscribe(email)

      if (result.success) {
        ReactGA.event({
          category: 'Sign Up',
          action: 'Successful Submit',
        })
        dispatch({
          type: ActionTypes.EMAIL_SUBMIT_SUCCESS,
        })
      } else {
        ReactGA.event({
          category: 'Sign Up',
          action: 'Failed Submit',
        })
        dispatch({
          type: ActionTypes.EMAIL_SUBMIT_FAILURE,
          message: 'Returned false manually',
        })
      }
    } catch (err) {
      console.log(JSON.stringify(err, null, 2))
      ReactGA.event({
        category: 'Sign Up',
        action: 'Failed Submit',
      })
      dispatch({
        type: ActionTypes.EMAIL_SUBMIT_FAILURE,
        message: err.message,
        code: err.code,
      })
    }
  }
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../img/logo.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "#logo-1 {\n  max-width: 315px;\n  margin-bottom: 30px;\n  margin-left: 4rem;\n  margin-right: 1.5rem;\n  margin-top: 0.7rem;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: contain;\n  width: 400px;\n  height: 65px;\n  padding: 0;\n}\n@media screen and (max-width: 1075px) {\n  #logo-1 {\n    margin-left: 1rem;\n  }\n}\n.welcome-page {\n  width: 100%;\n  height: 100%;\n  padding: 0;\n}\n.content-container {\n  font-size: 1.2rem;\n  padding-top: 1.5rem;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  flex: 0 0 80.5%;\n  max-width: 768px;\n  margin: 0;\n  padding-left: 1.5rem;\n  padding-right: 1.5rem;\n}\n@media screen and (min-width: 1075px) {\n  .content-container {\n    margin-left: calc(calc(100% - 768px) / 2);\n  }\n}\n@media screen and (max-width: 800px) {\n  .content-container {\n    padding-top: 0.5rem;\n  }\n}\n.welcome-header {\n  margin-left: 0;\n}\n#welcome-content {\n  padding-top: 5rem;\n}\n@media screen and (max-width: 800px) {\n  #welcome-content {\n    padding-top: 2rem;\n  }\n}\n#welcome-header {\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 8%;\n  box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,0.075) !important;\n}\n#welcome-footer {\n  position: fixed;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  height: 15%;\n  background-color: #99ca3c;\n  color: #2f3619;\n}\n@media screen and (max-width: 500px) {\n  #welcome-footer {\n    margin-top: 2rem;\n    position: static;\n  }\n}\n#subheading {\n  margin-top: -0.5rem;\n}\nh2 {\n  padding: 0;\n  margin-left: 0;\n  margin-top: 30px;\n  margin-bottom: 10px;\n}\nh3 {\n  margin-left: 0;\n  margin-top: 15px;\n  margin-bottom: 10px;\n}\np {\n  margin-top: 5px;\n  margin-bottom: 5px;\n}\nem {\n  font-size: 1.2rem;\n  margin-bottom: 1.5rem;\n}\n@media screen and (max-width: 1075px) {\n  em {\n    margin-bottom: 0.5rem;\n  }\n}\nul {\n  margin-bottom: 1.5rem;\n}\n", ""]);
// Exports
module.exports = exports;

// Import and combine all of our reducers into one
import { ActionTypes } from '../actions'

const StatusReducer = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.START_EMAIL_SUBMIT:
      return 'pending'
    case ActionTypes.EMAIL_SUBMIT_SUCCESS:
      return 'success'
    case ActionTypes.EMAIL_SUBMIT_FAILURE:
      return 'failure'
    default:
      return state
  }
}

export default StatusReducer

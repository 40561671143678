// Import and combine all of our reducers into one
import { combineReducers } from 'redux'

import StatusReducer from './status-reducer'
import ErrorReducer from './err-reducer'

const rootReducer = combineReducers({
  errorMessage: ErrorReducer,
  status: StatusReducer,
})

export default rootReducer

import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import Form from './form'
import ReactGA from 'react-ga'

import './home.styl'

class Home extends React.Component {
  constructor(props) {
    super(props)
  }

  // onClick = () => {
  //   this.props.setRand()
  //   const anotherRandomVal = Math.floor(Math.random() * 10)
  //   this.props.updateMetadata(anotherRandomVal)
  // }

  // GA Tracking
  componentDidMount() {
    ReactGA.pageview('/')
  }

  render() {
    return this.props.status === 'success' ? (
      <Redirect to="/welcome" />
    ) : (
      <div className="home-page">
        <div className="left-half">
          <div id="content-container">
            <div id="logo"></div>
            <h2 id="home-h2">Master keto in just 10 minutes each week</h2>
            <h3 id="home-h3">
              Take your ketogenic diet to the next level. Get inspired with low-carb recipes, tips &
              tricks, and motivational keto success stories, all for free.
            </h3>
            <Form />
          </div>
        </div>
        <div className="right-half"></div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  status: state.status,
})
//
// const mapDispatchToProps = dispatch => ({
//   setRand: () => dispatch(setRand()),
//   updateMetadata: val => dispatch(updateMetadata(val)),
// })

export default connect(mapStateToProps, null)(Home)

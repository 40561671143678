// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../img/logo.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".home-page {\n  width: 100%;\n  height: 100%;\n  padding: 0;\n}\n.left-half {\n  position: fixed;\n  padding: 0;\n  width: 72%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n@media screen and (max-width: 768px) {\n  .left-half {\n    width: 100%;\n    height: 72%;\n  }\n}\n@media screen and (max-width: 768px) {\n  .left-half {\n    justify-content: start;\n  }\n}\n#content-container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  flex: 0 0 80.5%;\n  max-width: 768px;\n  margin: 0;\n}\n@media screen and (min-width: 1075px) {\n  #content-container {\n    margin-left: calc(calc(100% - 768px) / 2);\n  }\n}\n#logo {\n  margin-bottom: 30px;\n  margin-left: 1.5rem;\n  margin-right: 1.5rem;\n  margin-top: 0;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: contain;\n  width: 400px;\n  height: 65px;\n  padding: 0;\n}\n@media screen and (max-width: 768px) {\n  #logo {\n    width: 90%;\n    margin-top: 10%;\n  }\n}\n#home-h2 {\n  color: #99ca3c !important;\n  font-weight: 600;\n  margin-right: 1.5rem;\n  margin-left: 1.5rem;\n  margin-bottom: 1rem;\n  margin-top: 0;\n  line-height: 1;\n  font-size: 30px !important;\n  padding: 0;\n}\n#home-h3 {\n  margin-left: 1.5rem;\n  margin-right: 1.5rem;\n  margin-bottom: 0.5rem;\n  margin-top: 0;\n  font-size: 20px;\n  font-weight: 400;\n  line-height: 1.4;\n  text-align: left;\n  padding: 0;\n}\nstrong {\n  font-weight: 600;\n}\n.right-half {\n  display: inline-block;\n  position: fixed;\n  background-color: #99ca3c;\n  height: 100%;\n  width: 28%;\n  left: 72%;\n}\n@media screen and (max-width: 768px) {\n  .right-half {\n    width: 0%;\n    display: none;\n  }\n}\n", ""]);
// Exports
module.exports = exports;

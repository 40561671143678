import React from 'react'

import ReactGA from 'react-ga' // Google Analytics

import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'

import Home from './home'
import Welcome from './welcome'
import Privacy from './privacy'

const gaTrackingId = 'UA-177542055-1'
ReactGA.initialize(gaTrackingId)

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/welcome" component={Welcome} />
        <Route exact path="/privacy" component={Privacy} />
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
    </Router>
  )
}

export default App
